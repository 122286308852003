import React, { FC, ReactElement } from 'react';

import SearchWidget from '../SearchWidget';
import './SearchWidgetContainer.scss';

const SearchWidgetContainer: FC = (): ReactElement => (
  <section className="SearchWidgetContainer">
    <h1>
      Get <span className="yellow">more</span> with our tyre comparison
    </h1>
    <p>Get the best tyre prices and buy now from independent fitters near you!</p>
    <SearchWidget />
  </section>
);

export default SearchWidgetContainer;
