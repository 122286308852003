import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import parse from 'react-html-parser';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import Expandable from '@motokiki/shared/expandable';
import SearchWidgetContainer from '@/components/SearchWidgetContainer';
import SEO from '@/components/SEO/Seo';

import '../styles/faqIndex.scss';


interface Props {
  data: {
    strapi: {
      faq: {
        Seo: {
          description: string;
          title: string;
        };
        frequently_asked_questions: {
          Answer: string;
          Question: string;
          id: string;
          map: (faqItem: {}) => any;
        }
        heroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerPrefix: string;
          headerText: string;
          iconImg: {
            url: string;
          }
          lozengeText: string;
          mainImg: {
            url: string;
          }
        }
      }
    }
  }  
}

const FAQ = ({ data }: Props): ReactElement => {
  const { heroSection, frequently_asked_questions, Seo } = data.strapi.faq;

  return (
    <>
    <SEO
      title={Seo.title}
      description={Seo.description}      
      />
    <Layout>
      <GeneralHeroSec data={heroSection} />
      <div className="faqContainer">
        {frequently_asked_questions.map((faqItem, id) => (
          <div className="faqItems">
            <Expandable key={id} title={faqItem.Question} height="auto">
              {parse(faqItem.Answer)}
            </Expandable>
          </div>
        ))}
      </div>
      <SearchWidgetContainer/>
    </Layout>
    </>
  );
};

export const query = graphql`
  query faq {
    strapi {
      faq(publicationState: PREVIEW) {
        Seo {
          description
          title
        }
        frequently_asked_questions {
          Answer
          Question
          id
        }
        heroSection {
          activeLozenge
          className
          displayIcon
          headerPrefix
          headerText
          iconImg {
            url
          }
          lozengeText
          mainImg {
            url
          }
        }
      }
    }
  }
`;
export default FAQ;
